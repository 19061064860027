.arrow-down {
    width: 100px;
    height: 100px;
    position: relative;
  }
  
  .arrow-down::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    width: 60px;
    height: 60px;
    border: 5px solid #999;
    border-top: none;
    border-right: none;
    border-radius: 50%;
    transform: rotate(135deg);
  }
  
  .arrow-down::after {
    content: '';
    position: absolute;
    bottom: -20px;
    left: 50%;
    transform: translateX(-50%);
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 20px solid #999;
  }
  

  .carousel-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    width: 100%;
  }
  
  .carousel-inner {
    display: flex;
    overflow: hidden;
    transition: transform 0.5s ease-in-out;
  }
  
  .carousel-item {
    flex: 1 0 auto;
    width: 100%;
  }
  
  .gradient-overlay {
    background: linear-gradient(to top, rgba(20, 20, 20, 0.6), rgba(0, 0, 0, 0));
  }
  